<template>
  <v-container>
    <v-row class="align-center justify-space-between">
      <v-col cols="12" md="5" class="align-center d-flex flex-column">
        <v-img class="mb-5" :src="settings.logo" :width="190" contain />
        <!-- <div class="grey--text mb-5" v-text="FOOTER_DESCRIPTION" /> -->
        <site-social-menu :menu="socialMenu" color="blue-grey darken-1" />
      </v-col>
      <v-col
        v-if="!isAgentSite"
        cols="12"
        md="6"
        style="row-gap: 10px"
        class="text-body-2 font-weight-semibold text--secondary d-inline-flex flex-wrap"
      >
        <div v-if="settings.business.name" class="w-50">
          <div class="text-caption font-weight-medium">Name</div>
          <div>{{ settings.business.name }}</div>
        </div>
        <div v-if="settings.business.phone" class="w-50">
          <div class="text-caption font-weight-medium">Phone</div>
          <div>{{ settings.business.phone }}</div>
        </div>
        <div v-if="settings.business.email" class="w-50">
          <div class="text-caption font-weight-medium">Email</div>
          <div>{{ settings.business.email }}</div>
        </div>
        <div v-if="settings.business.address" class="w-50">
          <div class="text-caption font-weight-medium">Address</div>
          <div>{{ settings.business.address }}</div>
        </div>
      </v-col>
    </v-row>
    <v-col cols="12" md="6" lg="5" xl="6">
      <v-row>
        <v-col cols="12" sm="6">
          <!-- <site-footer-menu :menu="serviceMenu" /> -->
        </v-col>
        <v-col cols="12" sm="6">
          <!-- <site-footer-menu :menu="companyMenu" /> -->
        </v-col>
      </v-row>
    </v-col>
    <v-col class="pa-0">
      <v-btn
        text
        small
        color="secondary"
        class="text-none mr-2"
        target="_blank"
        href="https://www.boomnow.com/privacy-policy"
      >
        Privacy Policy
      </v-btn>
      <v-btn
        text
        small
        color="secondary"
        class="text-none mr-2"
        target="_blank"
        href="https://www.boomnow.com/terms-of-conditions"
      >
        Terms of Conditions
      </v-btn>
    </v-col>
    <!-- <v-col cols="12" lg="4">
        <div class="text-h5 mb-4" v-text="FOOTER_FORM_TITLE" />
        <div class="grey--text" v-text="FOOTER_FORM_DESCRIPTION" />
      </v-col> -->
    <!-- <v-col cols="12" lg="8">
        <v-form>
          <v-row align="stretch">
            <v-col cols="12" sm="6" md="5">
              <v-text-field
                v-model="name"
                label="Firstname"
                outlined
                hide-details
              />
            </v-col>
            <v-col cols="12" sm="6" md="5">
              <v-text-field
                v-model="email"
                label="Email"
                outlined
                hide-details
              />
            </v-col>
            <v-col cols="12" md="2">
              <v-btn
                color="blue-grey darken-2"
                class="text-capitalize"
                dark
                block
                height="100%"
                min-height="56"
                elevation="0"
              >
                Sign up
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col> -->
    <!-- <site-social-menu
      :menu="socialMenu"
      color="blue-grey darken-1"
      class="d-md-none mb-8"
    /> -->
    <v-divider />
    <!-- <div class="d-flex flex-column flex-sm-row text-center grey--text py-6">
      <span class="mb-2 mb-sm-0 mr-sm-10">
        {{ new Date().getFullYear() }} {{ COMPANY_NAME }}
      </span>
      <router-link
        class="grey--text text-decoration-none"
        to="/terms"
        v-text="'Terms and conditions'"
      />
    </div> -->
  </v-container>
</template>

<script>
import {
  LOGO,
  COMPANY_NAME,
  FOOTER_DESCRIPTION,
  FOOTER_FORM_TITLE,
  FOOTER_FORM_DESCRIPTION,
} from "@/consts/CMS";
import MediaMixin from "@/mixins/media-mixin";
import SiteSocialMenu from "@/components/common/site-social-menu.vue";
import { mapState } from "vuex";

export default {
  name: "site-footer",
  mixins: [MediaMixin],
  components: { SiteSocialMenu },
  data: () => ({
    LOGO,
    COMPANY_NAME,
    FOOTER_DESCRIPTION,
    FOOTER_FORM_TITLE,
    FOOTER_FORM_DESCRIPTION,
    name: "",
    email: "",
  }),
  computed: {
    ...mapState({
      settings: (state) => state.settingsModule.settings,
    }),
    socialMenu() {
      const facebook = {
        name: "Facebook",
        icon: "mdi-facebook",
        url: this.settings.social_links.facebook,
      };
      const instagram = {
        name: "Instagram",
        icon: "mdi-instagram",
        url: this.settings.social_links.instagram,
      };
      const google = {
        name: "Google",
        icon: "mdi-google",
        url: this.settings.social_links.google,
      };
      return [facebook, instagram, google].filter((item) => item.url) || [];
    },
  },
};
</script>

<style scoped></style>
